import React, {useState, useEffect, useCallback} from 'react';
import Layout from '../components/layout';
import Footer2 from '../components/footer2';

import "../styles/signinStyles.scss";
import sp5 from '../assets/img/blex.png'
import sp6 from '../assets/img/documate.png'


import "../styles/tailwind.css";
import { Helmet } from 'react-helmet';
/* import IntervalTimer from 'react-interval-timer'; */
/* import { navigate } from "gatsby"; */
import { Link } from "gatsby"

import { motion} from 'framer-motion';
/* import { IdentityModal } from "react-netlify-identity-widget" */
import "react-netlify-identity-widget/styles.css" // delete if you want to bring your own CSS


const Login = props => {
 /*  const [showDialog, setShowDialog] = useState(false) */

    return <Layout>
        <Helmet>
        <title>Next.Legal</title>
                <meta name="description" content="Next.legal is a showcase of innovations in legal tech and finance for the African ecosystem." />
                <meta name="keywords" content="legal tech, startup, law" />
                <meta
                  name="robots"
                  content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
                />
                <link rel="canonical" href="https://nextlegal.io" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Next.Legal Lobby" />
                <meta property="og:description" content="Next.legal is a showcase of innovations in legal tech and finance for the African ecosystem." />
                <meta property="og:url" content="https://next-legal.io" />
                <meta property="og:site_name" content="Next.Legal" />
                <meta property="og:image" content="../assets/img/icon-256x256.png" />
                <meta property="og:image:secure_url" content="https://next-legal.io" />
                <meta property="og:image:width" content="1280" />
                <meta property="og:image:height" content="720" />
                <meta name="twitter:card" content="../assets/img/icon-256x256.png" />
                <meta name="twitter:description" content="Next.legal is a showcase of innovations in legal tech and finance for the African ecosystem." />
                <meta name="twitter:title" content="Next.Legal" />
                <meta name="twitter:image" content="../assets/img/icon-256x256.png" />
        </Helmet>
        {/* <IntervalTimer
                    timeout={4000}
                    callback={()=>{
                      navigate('/summit/lobby');
                     }}
                    enabled={true}
                /> */}
        <div className="signin-container">
          <motion.div 
               animate={{y:0 , opacity: 1}}
               initial={{ y: 72, opacity: 0 }}
               transition={{
                 duration: 0.5,
                 ease: [0.6, 0.05, -0.01, 0.9],
                 /* staggerChildren: 0.1 */
               }}
          className="signin-content"
          >
            <h2 className="signin-title">Summit Login</h2>
   {/*          <IdentityModal
                  showDialog={showDialog}
                  onCloseDialog={() => setShowDialog(false)}
                  onLogin={() => {
                    setShowDialog(false);
                    navigate("/app/summit")
                  
                  }}
                  onSignup={() => {
                    setShowDialog(false);
                    navigate("/app/summit")
                  
                  }}
                  onLogout={() => {
                    setShowDialog(false);
                    navigate("/")
                  
                  }}
      />  */}
            <div>
            <button className=" mt-3 px-6 py-3 tracking-wider bg-transparent hover:bg-blue-500 text-gray-800 hover:text-white font-semibold border border-gray-800 hover:border-transparent rounded-full"> 
          
              <Link to="/app/summit">
              Login
              </Link>
              </button>
            </div>
            </motion.div>
            <Footer2/>
          </div>
       
    </Layout>
}

export default Login
